import revive_payload_client_6RgfXkZZIZ from "/vercel/path0/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_@vercel+kv@1.0.1_encoding@0.1.13_eslint@_53ry7ul7eosd7wglgzbnswff44/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_zKC5HjEO9N from "/vercel/path0/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_@vercel+kv@1.0.1_encoding@0.1.13_eslint@_53ry7ul7eosd7wglgzbnswff44/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ju6jIUyGf6 from "/vercel/path0/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_@vercel+kv@1.0.1_encoding@0.1.13_eslint@_53ry7ul7eosd7wglgzbnswff44/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_M6aIPGs42j from "/vercel/path0/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.5_rollup@4.21.1_vite@5.4.2_@types+node@22.5.1_terser@5.3_oi5w3fa2pwyjwcpvfgllnws7pe/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import cv_L2P715W5Mw from "/vercel/path0/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-storyblok@1.6.2_@bugsnag+core@7.25.0_esbuild@0.17.19_ioredis@5.4.1_magi_kvnfeemt62drfvlv7q6shxqyli/node_modules/@bttr-devs/nuxt-layer-storyblok/plugins/cv.ts";
import init_X5y0w4sZG6 from "/vercel/path0/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-storyblok@1.6.2_@bugsnag+core@7.25.0_esbuild@0.17.19_ioredis@5.4.1_magi_kvnfeemt62drfvlv7q6shxqyli/node_modules/@bttr-devs/nuxt-layer-storyblok/plugins/init.ts";
import payload_client_LaQzSVt9gY from "/vercel/path0/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_@vercel+kv@1.0.1_encoding@0.1.13_eslint@_53ry7ul7eosd7wglgzbnswff44/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Py8utWL6Vp from "/vercel/path0/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_@vercel+kv@1.0.1_encoding@0.1.13_eslint@_53ry7ul7eosd7wglgzbnswff44/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dFOHhG3Vgj from "/vercel/path0/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_@vercel+kv@1.0.1_encoding@0.1.13_eslint@_53ry7ul7eosd7wglgzbnswff44/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2Tva2juC4J from "/vercel/path0/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_@vercel+kv@1.0.1_encoding@0.1.13_eslint@_53ry7ul7eosd7wglgzbnswff44/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_TsFMvdDGdZ from "/vercel/path0/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_@vercel+kv@1.0.1_encoding@0.1.13_eslint@_53ry7ul7eosd7wglgzbnswff44/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import defaults_jxdmvLEhhW from "/vercel/path0/nuxt/node_modules/.pnpm/nuxt-schema-org@3.3.9_magicast@0.3.5_rollup@4.21.1_vite@5.4.2_@types+node@22.5.1_terser@5.31._ownhp4lnn47e76icleutpkv5je/node_modules/nuxt-schema-org/dist/runtime/nuxt/plugin/defaults.js";
import plugin_jBAy4GEZ6M from "/vercel/path0/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.21.1/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_mzOZrRA8tv from "/vercel/path0/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.1_vue@3.4.38_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_3Y0UeKUP0G from "/vercel/path0/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.1_vue@3.4.38_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/nuxt/.nuxt/formkitPlugin.mjs";
import errors_l6pIwM5Qxk from "/vercel/path0/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.17.19_magicast@0.3.5_nuxt@3.1_ezx5u5537ff7jkcjbphxuj2op4/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_bJmwTFDHXx from "/vercel/path0/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.17.19_magicast@0.3.5_nuxt@3.1_ezx5u5537ff7jkcjbphxuj2op4/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import preview_client_cQfl4Bwb3p from "/vercel/path0/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-storyblok@1.6.2_@bugsnag+core@7.25.0_esbuild@0.17.19_ioredis@5.4.1_magi_kvnfeemt62drfvlv7q6shxqyli/node_modules/@bttr-devs/nuxt-layer-storyblok/plugins/preview.client.ts";
import dynamic_vw_client_kS7b5RjaKG from "/vercel/path0/nuxt/plugins/dynamic-vw.client.ts";
import init_HuVeeuCOGi from "/vercel/path0/nuxt/node_modules/.pnpm/nuxt-schema-org@3.3.9_magicast@0.3.5_rollup@4.21.1_vite@5.4.2_@types+node@22.5.1_terser@5.31._ownhp4lnn47e76icleutpkv5je/node_modules/nuxt-schema-org/dist/runtime/nuxt/plugin/init.js";
export default [
  revive_payload_client_6RgfXkZZIZ,
  unhead_zKC5HjEO9N,
  router_ju6jIUyGf6,
  _0_siteConfig_M6aIPGs42j,
  cv_L2P715W5Mw,
  init_X5y0w4sZG6,
  payload_client_LaQzSVt9gY,
  navigation_repaint_client_Py8utWL6Vp,
  check_outdated_build_client_dFOHhG3Vgj,
  chunk_reload_client_2Tva2juC4J,
  components_plugin_KR1HBZs4kY,
  prefetch_client_TsFMvdDGdZ,
  defaults_jxdmvLEhhW,
  plugin_jBAy4GEZ6M,
  switch_locale_path_ssr_mzOZrRA8tv,
  i18n_3Y0UeKUP0G,
  formkitPlugin_pZqjah0RUG,
  errors_l6pIwM5Qxk,
  i18n_bJmwTFDHXx,
  preview_client_cQfl4Bwb3p,
  dynamic_vw_client_kS7b5RjaKG,
  init_HuVeeuCOGi
]