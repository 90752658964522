import { default as _91_46_46_46page_93bbvW8s9pnrMeta } from "/vercel/path0/nuxt/pages/[...page].vue?macro=true";
export default [
  {
    name: "page___en",
    path: "/:page(.*)*",
    meta: _91_46_46_46page_93bbvW8s9pnrMeta || {},
    component: () => import("/vercel/path0/nuxt/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___nl",
    path: "/nl/:page(.*)*",
    meta: _91_46_46_46page_93bbvW8s9pnrMeta || {},
    component: () => import("/vercel/path0/nuxt/pages/[...page].vue").then(m => m.default || m)
  }
]